import logo from './logo.svg';
import './App.css';
import { CreatorModul } from "@myyolo/myyolo-components.trainingplan.creator-modul"
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react'
import { Box } from '@mui/system';
import data from './static/data.json';


function App() {


  const theme = createTheme({
    palette: {
      primary: {
        main: '#C9358E',
        contrastText: '#fff',
      },
      secondary: {
        main: '#2B4254',
        light: '#2C415C',
        contrastText: '#fff',
      },
      warning: {
        main: '#F2B61B',
      },
      info: {
        main: '#C9358E',
      },
      success: {
        main: '#03A544',
      },
      error: {
        main: '#AD0000',
      },
    },
    shape: {
      borderRadius: 35
    },
    typography: {
      fontFamily: 'Heebo',
      h1: {
        fontSize: '1.5rem',
      },
      body1: {
        fontSize: '1rem',
      }
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '40px !important',
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '40px !important',
            paddingLeft: "1.5em",
            paddingRight: "1.5em",
            minWidth: "130px"
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: "#2C415C",
            textDecoration: "none",
          }
        }
      }

    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: "5em", background: "#C9358E" }}></Box>
      <Box sx={{ marginTop: "10vh" }}>
        <CreatorModul data={data} />
      </Box>

    </ThemeProvider >
  );
}

export default App;
